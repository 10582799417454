import "./bootstrap";
import * as Sentry from "@sentry/svelte";
import EmblaCarousel from "embla-carousel";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

window.EmblaCarousel = EmblaCarousel;

import {
    Livewire,
    Alpine,
} from "../../vendor/livewire/livewire/dist/livewire.esm";
import Autosize from "@marcreichel/alpine-autosize";

Alpine.directive("clipboard", (el) => {
    let text = el.textContent;

    el.addEventListener("click", () => {
        navigator.clipboard.writeText(text);
    });
});

Alpine.plugin(Autosize);

document.addEventListener("livewire:navigate", (event) => {
    let context = event.detail;

    if (context.history === true) {
        event.preventDefault();
        window.location.href = context.url.href;
    }
});

document.addEventListener("livewire:navigated", () => {
    // Check if there is a target query parameter in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const target = urlParams.get("target");

    // If there is a target query parameter, scroll to the target element
    if (target) {
        const targetElement = document.getElementById(target);

        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    }
});

document.addEventListener("livewire:init", () => {
    Livewire.on("response-deleted", (event) => {
        const element = document.getElementById(`response-${event[0]}`);

        if (element) {
            element.remove();
        }
    });
});

Livewire.start();
